<template>
  <Modal :activeModal="activeModal" @closeModal="closeModal">
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
        Relatório de Jogos por Revendedor 
        <v-spacer></v-spacer>
        <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <v-container fluid class="pb-0">
        <v-col>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col class="pa-3 pt-0 pb-1">
                  <!-- Periodo: {{ rangeDate.dtInicial | data('DD/MM/YYYY') }} a {{ rangeDate.dtFinal | data }} -->
                  <strong>Data de Referência:</strong> {{ data }}
                </v-col>

                <v-col class="text-right pa-3 pt-0 pb-1">
                  <strong>Revendedor:</strong> {{ revendedor }} 
                </v-col>
              </v-row>

              <v-row class="align-end">
                <v-col class="pa-3 pt-0 pb-1">
                  <Date />
                </v-col>

                <v-col class="pa-3 pt-0 pb-1">
                  <span class="text-right d-block">
                    <strong>Bilhete:</strong> {{ nrJogo }}
                  </span>
                  <span class="text-right d-block" v-if="isBilheteCancelado(dtDeleted)">
                    <strong>Cancelado:</strong> {{ dtDeleted | datas }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-container>

      <v-col class="d-flex justify-center align-center" style="min-height: 300px; width: 100%;" v-if="loadingModal">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>

      
      <v-container fluid class="mt-0" v-else>
        <v-col>
          <div class="box-table box-table-ganhadores">
            <v-data-table
              :headers="headers"
              :items="listGames"
              :items-per-page="5"
              width="300px"
              height="auto"
              flat
              dense
              show-expand
              single-expand
            >
              <!-- <template v-slot:body="{ items }">
                
              </template> -->

              <template v-slot:item.value="{ item }">
                {{ item.value | currency }}
              </template>

              <template v-slot:item.sweepstakes.dtDraw="{item}">
                {{ item.sweepstakes.dtDraw | datas('DD/MM/YYYY HH:mm:ss') }}
              </template>

              <template slot="body.append">
                <tr class="">
                  <th :colspan="headers.length + 1">Total: {{ totalValoresJogos | currency}}</th>
                </tr>
              </template>

              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <div class="box-table-palpites">
                    <v-data-table
                      :headers="headersSubTable"
                      :items="item.lotteryGuesses"
                      hide-default-footer
                      dense
                      :items-per-page="item.lotteryGuesses.length"
                    >
                      <template v-slot:item.value="{item}">
                        {{ item.value | currency }}
                      </template>
                    </v-data-table>
                  </div>
                </td>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-container>
    </v-card>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
// import { required } from 'vuelidate/lib/validators'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
import moment from 'moment'

import validacao from './validacao'

const namespaceStore = 'historicoVendas'

export default {
  name: 'ModalJogos',
  mixins: [validationMixin],
  components: {
    Modal: () => import('@/views/components/modal'),
    Date: () => import('@/views/components/date')
  },
  data: () => ({
    activeModal: false,
    loading: false,
    entityId: null,
    dtFinal: null,
    dtInicial: null,
    lotteryGuesses: null,
    lotteryGames: null,
    loadingModal: false,
    nrJogo: null,
    data: '',
    dtDeleted: '',
    revendedor: '',
    headers: [
      { sortable: false, class: 'table-header', text: 'Loteria', value: 'sweepstakes.cod' },
      { sortable: false, class: 'table-header', text: 'Data sorteio', value: 'sweepstakes.dtDraw' },
      { sortable: false, class: 'table-header', text: 'Modalidade', value: 'modalities.name' },
      // { sortable: false, class: 'table-header', text: 'Palpites', value: 'lotteryGuesses.guess' },
      { sortable: false, class: 'table-header', text: 'Prêmio', value: 'prizeRange' },
      { sortable: false, class: 'table-header', text: 'Vl. jogo', value: 'value' },
    ],

    headersSubTable: [
      { sortable: false, class: 'table-header', text: 'Palpites', value: 'guess' },
      { sortable: false, class: 'table-header', text: 'Vl. jogo', value: 'value' },
    ]
  }),
  
  computed: {
    ...mapGetters(namespaceStore, ['listaItens', 'totalItens', 'transmissoes', 'listGames']),
    ...validacao,
    variables: () => variables,
    isEdit () {
      return this.item.id ? !!1 : !!0
    },

    totalValoresJogos () {
      return this.listGames.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue.value)
      }, 0)      
    }
  },
  mounted () {
    Events.$on('modal::historicoVendas::jogos', (e) => {
      this.activeModal = true
      this.data = e.data
      this.revendedor = e.revendedor
      this.nrJogo = e.val.gameNumber
      this.dtDeleted = e.val.deleted

      this.loadingModal = true

      this.getListJogos({ id: e.val.id })
        .then(() => {
          const data = this.listGames.filter(item => moment(item.deleted).format('DD/MM/YYYY') !== '01/01/1970')
          console.log(data)
        })
        .finally(() => this.loadingModal = false)
    })
  },
  methods: {
    ...mapActions(namespaceStore, ['getItens', 'buscarItem', 'deletarItem', 'getItem', 'getFilterVendas', 'getListJogos', 'limparItemModalJogos']),
    openModal () {
      this.activeModal = true
    },

    closeModal () {
      this.activeModal = false
      this.limparItemModalJogos()
    },

    isBilheteCancelado (date) {
      if (moment(date).format('DD/MM/YYYY') !== '01/01/1970') return true

      return false
    }
  },

  beforeDestroy () {
    Events.$off('modal::historicoVendas::jogos')
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/formularios';
  @import '~@/assets/styles/components/modal-cadastro';

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }

    &.box-table-ganhadores {
     .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
        // padding: 15px 0;
      } 

      .v-data-table tbody tr.v-data-table__expanded__content {
        box-shadow: none;
      }
    }

    .box-table-palpites {
      .v-data-table {
        margin: 20px 0
      }
    } 
  }
</style>