var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"activeModal":_vm.activeModal},on:{"closeModal":_vm.closeModal}},[_c('v-card',[_c('v-toolbar',{staticClass:"modal-cadastro-toolbar",attrs:{"flat":"","height":"40px"}},[_vm._v(" Relatório de Jogos por Revendedor "),_c('v-spacer'),_c('v-icon',{staticClass:"modal-cadastro-close",on:{"click":_vm.closeModal}},[_vm._v("close")])],1),_c('v-container',{staticClass:"pb-0",attrs:{"fluid":""}},[_c('v-col',[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pa-3 pt-0 pb-1"},[_c('strong',[_vm._v("Data de Referência:")]),_vm._v(" "+_vm._s(_vm.data)+" ")]),_c('v-col',{staticClass:"text-right pa-3 pt-0 pb-1"},[_c('strong',[_vm._v("Revendedor:")]),_vm._v(" "+_vm._s(_vm.revendedor)+" ")])],1),_c('v-row',{staticClass:"align-end"},[_c('v-col',{staticClass:"pa-3 pt-0 pb-1"},[_c('Date')],1),_c('v-col',{staticClass:"pa-3 pt-0 pb-1"},[_c('span',{staticClass:"text-right d-block"},[_c('strong',[_vm._v("Bilhete:")]),_vm._v(" "+_vm._s(_vm.nrJogo)+" ")]),(_vm.isBilheteCancelado(_vm.dtDeleted))?_c('span',{staticClass:"text-right d-block"},[_c('strong',[_vm._v("Cancelado:")]),_vm._v(" "+_vm._s(_vm._f("datas")(_vm.dtDeleted))+" ")]):_vm._e()])],1)],1)],1)],1)],1),(_vm.loadingModal)?_c('v-col',{staticClass:"d-flex justify-center align-center",staticStyle:{"min-height":"300px","width":"100%"}},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1):_c('v-container',{staticClass:"mt-0",attrs:{"fluid":""}},[_c('v-col',[_c('div',{staticClass:"box-table box-table-ganhadores"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.listGames,"items-per-page":5,"width":"300px","height":"auto","flat":"","dense":"","show-expand":"","single-expand":""},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.value))+" ")]}},{key:"item.sweepstakes.dtDraw",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("datas")(item.sweepstakes.dtDraw,'DD/MM/YYYY HH:mm:ss'))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"box-table-palpites"},[_c('v-data-table',{attrs:{"headers":_vm.headersSubTable,"items":item.lotteryGuesses,"hide-default-footer":"","dense":"","items-per-page":item.lotteryGuesses.length},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.value))+" ")]}}],null,true)})],1)])]}}])},[_c('template',{slot:"body.append"},[_c('tr',{},[_c('th',{attrs:{"colspan":_vm.headers.length + 1}},[_vm._v("Total: "+_vm._s(_vm._f("currency")(_vm.totalValoresJogos)))])])])],2)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }